<template>
  <div v-if="order">
    <v-card v-if="isMobile" class="details-card elevation-0">
      <section class="order-header">
        <div style="display: flex;align-items: center;justify-content: space-between; gap: 16px">
          <img :src="backIcon" alt="backicon" @click="goBack">
          <p class="break-word fs-18 color-dark p-m-0" style="font-weight: 600;">Order #{{ order.invoiceNumber }}</p>
        </div>

        <div @click="print" class="d-flex align-items-center" style="color: #e2136e;gap: 2px;margin-left: 16px;">
          <img :src="printIcon" alt="print icon">
          <span class="fs-14 fw-400" style="margin-left: 2px">Print</span>
        </div>
      </section>

      <section class="product-details" style="display:flex;justify-content: space-between;align-items: end">
        <div class="product" style="display: flex;gap:20px">
          <div v-if="order.image">
            <img :src="order.image?order.image:null" style="border-radius: 4px" height="80px" width="80px"
                 alt="Product Image">
          </div>
          <div>
            <p class="break-word color-dark fw-500 fs-14">{{ order.productName ? order.productName : "N/A" }}</p>
            <p class="fs-12 fw-400 color-light">Qty x {{ order.quantity }}</p>
            <div class="color-dark" style="display: inline-flex;gap: 5px;align-items: center">
                <span
                    :style="{color: order.orderStatus==='Incomplete'? '#FFB829' : '#01846C'}"
                    class="mdi mdi-circle"
                >
                </span>
              <p class="p-m-0 fs-12 fw-500">{{ order.orderStatus }}</p>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column; align-items: center;gap: 8px">
          <p class="color-dark fs-14 fw-500 p-m-0">৳{{ order.amount }}</p>
          <div
              style="height: 20px;width: 60px;background: #F6F6F6;border-radius: 44px;display: flex;justify-content: center;align-items: center;">
            <p class="p-m-0 color-light fw-500 fs-12">{{ order.paymentStatus }}</p>
          </div>
        </div>
      </section>

      <v-divider style="margin-top: 16px;margin-bottom: 12px"></v-divider>

      <section class="action-button" style="display: flex;align-items: center;justify-content: center">
        <div v-if="incomplete" @click="openMarkDialog"
             style="cursor: pointer;height: 32px;width: 296px;background: #FAEDF3;border-radius: 4px;display: inline-flex; gap: 5px;align-items: center;justify-content: center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="6" fill="#E2136E"/>
            <path
                d="M8.0001 1.33301C4.32403 1.33301 1.33334 4.32365 1.33334 7.99967C1.33334 11.6757 4.32403 14.6663 8.0001 14.6663C11.6761 14.6663 14.6668 11.6757 14.6668 7.99967C14.6668 4.32365 11.6761 1.33301 8.0001 1.33301ZM8.0001 13.5734C4.92665 13.5734 2.42624 11.0731 2.42624 7.99967C2.42624 4.92628 4.92665 2.4259 8.0001 2.4259C11.0735 2.4259 13.5739 4.92628 13.5739 7.99967C13.5739 11.0731 11.0735 13.5734 8.0001 13.5734Z"
                fill="#E2136E"/>
            <path
                d="M10.8842 5.43932L6.92588 9.39766L5.11613 7.58791C4.90271 7.37454 4.55674 7.37454 4.34332 7.58791C4.12994 7.80133 4.12994 8.1473 4.34332 8.36072L6.53947 10.5569C6.64618 10.6635 6.78603 10.7169 6.92588 10.7169C7.06573 10.7169 7.20557 10.6635 7.31228 10.5569L11.657 6.21218C11.8704 5.99876 11.8704 5.65279 11.657 5.43937C11.4436 5.22595 11.0976 5.22595 10.8842 5.43932Z"
                fill="white"/>
          </svg>
          <p class="p-m-0" style="color: #e2136e">Mark Order Complete</p>
        </div>
        <v-dialog persistent v-model="markDialog" width="320">
          <v-card style="padding: 16px">
            <div style="display: flex; justify-content: space-between;align-items: center">
              <h2 class="color-dark fs-18">Mark Order Complete</h2>
              <v-icon @click="closeMarkDialog" color="primary">mdi-close</v-icon>
            </div>
            <p class="color-dark fw-400 fs-14">Are you sure want to Mark this order
              complete?</p>
            <div style="display: flex;align-items: center;gap:16px;">
              <v-btn @click="closeMarkDialog" color="primary" outlined style="height: 32px">Cancel</v-btn>
              <v-btn @click="markOrderAsComplete" color="primary" style="height: 32px">Mark Complete</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="markSuccessDialog" width="320">
          <v-card style="padding: 16px">
            <div style="display: flex; justify-content: space-between;align-items: start">
              <div>
                <img :src="successIcon" alt="successMark">
                <h2 class="color-dark fs-18">Mark Order Complete</h2>
              </div>
              <v-icon @click="closeSuccessDialog" color="primary">mdi-close</v-icon>
            </div>
            <p class="fs-14 color-dark">This order has been marked as completed</p>
            <v-btn @click="closeSuccessDialog" color="primary" outlined style="height: 32px">Close</v-btn>
          </v-card>
        </v-dialog>
      </section>

      <v-divider v-if="incomplete" style="margin-top: 12px;margin-bottom: 16px"></v-divider>

      <section class="order-summary-section">
        <p class="section-title">Order Summary</p>
        <div class="summary">
          <div class="mini-summary">
            <p class="data">Invoice ID</p>
            <p class="value">{{ order.invoiceNumber }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Order Date</p>
            <p class="value">{{ order.createdDate }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Link ID</p>
            <p class="value">{{ order.paymentLinkId }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Customer Wallet</p>
            <p class="value">{{ order.customerPhoneNumber }}</p>
          </div>
          <!--ADDITIONAL INFORMATION-->
          <div
              v-for="(value, key) in order.customerInformation"
              :key="key"
          >
            <div class="mini-summary" v-if="!!value">
              <p class="data">{{ key }}</p>
              <p class="value break-word">{{ value }}</p>
            </div>
          </div>
        </div>
      </section>

      <v-divider style="margin-top: 16px;margin-bottom: 16px"></v-divider>

      <section class="traction-details-section">
        <p class="section-title">Transaction Details</p>
        <div class="summary">
          <div class="mini-summary">
            <p class="data">Txn ID</p>
            <p class="value">{{ order.trxId }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Wallet</p>
            <p class="value">{{ order.merchantWallet }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Amount</p>
            <p class="value">৳{{ order.amount }}</p>
          </div>
        </div>
      </section>
    </v-card>

    <v-card v-else class="details-card elevation-0">
      <section class="order-header">
        <p class="break-word fs-24 color-dark p-m-0" style="font-weight: 600;">Order #{{ order.invoiceNumber }}</p>

        <div v-if="incomplete" class="mark-complete" @click="openMarkDialog">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="9" fill="#E2136E"/>
            <path
                d="M12.0001 2C6.48603 2 2 6.48597 2 12C2 17.514 6.48603 22 12.0001 22C17.5142 22 22.0001 17.514 22.0001 12C22.0001 6.48597 17.5142 2 12.0001 2ZM12.0001 20.3607C7.38997 20.3607 3.63934 16.6102 3.63934 12C3.63934 7.3899 7.38997 3.63934 12.0001 3.63934C16.6102 3.63934 20.3608 7.3899 20.3608 12C20.3608 16.6102 16.6102 20.3607 12.0001 20.3607Z"
                fill="#E2136E"/>
            <path
                d="M16.3262 8.15996L10.3888 14.0975L7.67416 11.3828C7.35403 11.0628 6.83508 11.0628 6.51495 11.3828C6.19489 11.703 6.19489 12.2219 6.51495 12.5421L9.80918 15.8363C9.96925 15.9963 10.179 16.0764 10.3888 16.0764C10.5986 16.0764 10.8083 15.9963 10.9684 15.8363L17.4854 9.31924C17.8055 8.99911 17.8055 8.48016 17.4854 8.16003C17.1653 7.8399 16.6463 7.8399 16.3262 8.15996Z"
                fill="white"/>
          </svg>
          <p class="p-m-0 fw-500 fs-18" style="color: #e2136e;">
            Mark Order Complete
          </p>
        </div>
        <v-dialog persistent v-model="markDialog" width="535">
          <v-card style="padding: 30px">
            <div style="display: flex; justify-content: space-between;align-items: center">
              <h2 class="color-dark">Mark Order Complete</h2>
              <v-icon @click="closeMarkDialog" color="primary">mdi-close</v-icon>
            </div>
            <p class="color-dark" style="font-weight: 400; font-size: 18px;">Are you sure want to Mark this order
              complete?</p>
            <div style="display: flex;align-items: center;gap:16px;">
              <v-btn @click="closeMarkDialog" color="primary" outlined>Cancel</v-btn>
              <v-btn @click="markOrderAsComplete" color="primary">Mark Complete</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="markSuccessDialog" width="535">
          <v-card style="padding: 30px">
            <div style="display: flex; justify-content: space-between;align-items: start">
              <div>
                <img :src="successIcon" alt="successMark">
                <h2 class="color-dark">Mark Order Complete</h2>
              </div>
              <v-icon @click="closeSuccessDialog" color="primary">mdi-close</v-icon>
            </div>
            <p>This order has been marked as completed</p>
            <v-btn @click="closeSuccessDialog" color="primary" outlined>Close</v-btn>
          </v-card>
        </v-dialog>
      </section>

      <section class="product-details" style="display: flex; align-items: end;justify-content: space-between;">
        <div class="product" style="display: flex;gap:20px">
          <div v-if="order.image">
            <img :src="order.image?order.image:null" style="border-radius: 4px" height="109px" width="109px"
                 alt="Product Image">
          </div>
          <div style="display: flex;align-items: center">
            <div>
              <p class="break-word color-dark fw-500 fs-18">{{ order.productName ? order.productName : "N/A" }}</p>
              <p class="fs-18 fw-400 value">Qty x {{ order.quantity }}</p>
              <div style="display: inline-flex;gap: 40px;align-items: center">
                <p class="color-dark fs-18 fw-500 p-m-0">৳{{ order.amount }}</p>
                <div
                    style="height: 24px;width: 60px;background: #F6F6F6;border-radius: 44px;display: flex;justify-content: center;align-items: center;">
                  <p class="p-m-0 value fw-500 fs-14">Paid</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="statusPrint" style="display: inline-flex;align-items: center;gap: 10px">
          <div style="display: inline-flex;gap: 5px;align-items: center">
          <span
              :style="{color: order.orderStatus==='Incomplete'? '#FFB829' : '#01846C'}"
              class="mdi mdi-circle"
          >
          </span>
            <p class="p-m-0">{{ order.orderStatus }}</p>
          </div>
          <v-btn @click="print" outlined color="primary" style="height: 32px;width: 86px">
            <img :src="printIcon" alt="print icon">
            <span class="fs-14 fw-400" style="margin-left: 2px">Print</span>
          </v-btn>
        </div>
      </section>

      <v-divider style="margin-top: 40px;margin-bottom: 16px"></v-divider>

      <section class="order-summary-section">
        <p class="section-title">Order Summary</p>
        <div class="summary">
          <div class="mini-summary">
            <p class="data">Invoice ID</p>
            <p class="value">{{ order.invoiceNumber }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Order Date</p>
            <p class="value">{{ order.createdDate }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Link ID</p>
            <p class="value">{{ order.paymentLinkId }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Customer Wallet</p>
            <p class="value">{{ order.customerPhoneNumber }}</p>
          </div>
          <!--ADDITIONAL INFORMATION-->
          <div
              v-for="(value, key) in order.customerInformation"
              :key="key"
          >
            <div class="mini-summary" v-if="!!value">
              <p class="data">{{ key }}</p>
              <p class="value break-word">{{ value }}</p>
            </div>
          </div>
        </div>
        <v-divider style="margin-top: 32px;margin-bottom: 16px"></v-divider>
      </section>

      <section class="traction-details-section">
        <p class="section-title">Transaction Details</p>
        <div class="summary">
          <div class="mini-summary">
            <p class="data">Txn ID</p>
            <p class="value">{{ order.trxId }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Wallet</p>
            <p class="value">{{ order.merchantWallet }}</p>
          </div>
          <div class="mini-summary">
            <p class="data">Amount</p>
            <p class="value">৳{{ order.amount }}</p>
          </div>
        </div>
      </section>
    </v-card>
  </div>
</template>

<script>
import printIcon from "@/assets/image/printer.svg";
import successIcon from "@/assets/image/success-icon.svg";
import backIcon from "@/assets/image/back-icon.svg";

export default {
  name: "OrderDetails",
  props: ["orderId"],

  created() {
    this.fetchOrderDetailsData();
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
    incomplete() {
      return this.order.orderStatus === "Incomplete";
    },
  },

  data() {
    return {
      order: null,
      payload: {
        "orderId": this.orderId
      },
      payloadToMarkOrderComplete: [{
        orderId: this.orderId,
        orderStatus: "Completed"
      }],
      printIcon: printIcon,
      backIcon: backIcon,
      markDialog: false,
      markSuccessDialog: false,

      successIcon: successIcon,

      merchantInfo: {
        merchantName: "",
        merchantContactEmail: "",
        merchantWallet: ""
      },
    }
  },

  methods: {
    async fetchOrderDetailsData() {
      this.$feedback.showLoading();
      try {
        let data = await this.$cppClient.post('/order/details', this.payload);
        this.order = data.data;
        this.$feedback.hideLoading();

      } catch (err) {
        this.$feedback.hideLoading();
        await this.$feedback.showFailed(err.response);
        await this.$router.push({ name: "dashboard" });
      }
    },

    async markOrderAsComplete() {
      this.$feedback.showLoading();
      try {
        let result = await this.$cppClient.post('/order-status/update', {orderUpdateRequests: this.payloadToMarkOrderComplete});

        this.markSuccessDialog = true;
        this.markDialog = false;
        console.log({result});
        this.$feedback.hideLoading();
        await this.fetchOrderDetailsData();
      } catch (err) {
        this.$feedback.hideLoading();
        this.markSuccessDialog = false;
        await this.$feedback.showFailed(err.response);
      }
    },

    openMarkDialog() {
      this.markDialog = true;
    },

    closeMarkDialog() {
      this.markDialog = false;
    },

    closeSuccessDialog() {
      this.markSuccessDialog = false;
    },

    goBack() {
      this.$router.go(-1);
    },

    print() {
      this.$router.push({
        name: "invoice",
        params: {orderId: this.orderId},
      });
    }
  }
}
</script>

<style scoped lang="scss">
.p-m-0 {
  margin: 0 !important;
}

.break-word {
  word-break: break-all;
}

.details-card {
  padding: 40px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 799px;
  align-self: center;

  @media screen and (max-width: 1000px) {
    width: 428px;
    padding: 16px;
  }

  @media screen and (max-width: 456px) {
    width: 320px;
    padding: 16px;
  }
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.mark-complete {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin-left: 16px;
}

.section-title {
  color: #464646;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 32px !important;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px !important;
  }
}

.summary div:empty {
  display: none;
}

.summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 50px;

  @media screen and (max-width: 1000px) {
    column-gap: 20px;
  }
}

.mini-summary {
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media screen and (max-width: 1000px) {
    display: unset;
  }
}

.data {
  color: #464646;
  font-weight: 500;
  margin-bottom: 0 !important;

  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
}

.value {
  color: #777;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1000px) {
    color: #464646;
    font-size: 12px;
  }
}

.color-dark {
  color: #464646;
}

.color-light {
  color: #777;
}

.fs-24 {
  font-size: 24px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

</style>